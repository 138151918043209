html, body {
  background-color: #111;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  position: fixed;
}

canvas {
  margin-left: 0;
  margin-top: 0;
  display: block;
  position: fixed;
}

.App-link {
  color: #61dafb;
}

.containerStyle {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; 
  font-size: calc(5px + 4vmin);
}

.particlesBG {
  background-color: #111;
}

.black {
  background-color: #111;
}

.center {
  text-align: center;
  align-content: center;
}